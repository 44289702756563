import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './modal.scss';

const Modal = props => {

    useEffect(() => {
        const keyPressHandler = (e) => {
            if (e.keyCode === 27)
                props.onHide && props.onHide()
        };

        document.addEventListener('keydown', keyPressHandler);
        return () => {
            document.removeEventListener('keydown', keyPressHandler);
        };
    }, [props]);

    const handleWrapperClick = (e) => {
        if (e.target.matches('.modalWrapper')) {
            props.onHide && props.onHide()
        }
    }

    if (!props.show) return null;
    return (
        <div
            className="modalWrapper"
            onClick={handleWrapperClick}
        >
            <div className="modalContent">
                {props.children}
            </div>
        </div>
    );
}

Modal.propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func
}

export default Modal;
