import React from 'react';

import { sortableContainer } from 'react-sortable-hoc';

const SortableContainer = sortableContainer((props) => {
    return (
        <ul>{props.children}</ul>
    );
});

export default SortableContainer;