import React from 'react';
import PropTypes from 'prop-types';
import TagList from '../Tag/List';
import UserList from '../User/List';

import { sortableElement, sortableHandle } from 'react-sortable-hoc';

const DragHandle = sortableHandle(() => <i className="icon-menu sortable" />);

const SortableItem = sortableElement(props =>
    <li style={{'zIndex': '999999999999999'}}>
        <div data-id={props.tarea.id} className={'list-item draggableItem ' + (props.tarea.finalizar === 1 ? 'done' : '')}>
            <div className="list-item-body">
                <DragHandle />
                <a href={props.tarea.updateUrl}>{props.tarea.nombre}</a>
            </div>
            <footer>

                {props.tarea.tags.length > 0 &&
                    <div className="tags">
                        <TagList
                            showTagTitle={props.showTagTitle}
                            onSetTitle={props.onSetTitle}
                            items={props.tarea.tags}
                        />
                    </div>
                }

                {props.tarea.users.length > 0 &&
                    <div className="users">
                        <UserList items={props.tarea.users} />
                    </div>
                }
            </footer>
        </div>
    </li>)

SortableItem.propTypes = {
    index: PropTypes.number.isRequired,
    lista: PropTypes.object.isRequired,
}

export default SortableItem;
