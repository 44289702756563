import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';

const Item = ({ item, selected, onAction, onMouseEnter, scrollEnabled, onSetCurrentItem, nombreCli, nombrePro, onSetNombreCliente, onSetNombreProyecto }) => {

    let node = useRef(null);
    const [mouseFocus, setMouseFocus] = useState(false);

    const handleActionType = tipo => {
        switch (tipo) {
            case 'cliente':
                return 'Crear proyecto y tarea e iniciar';
            case 'proyecto':
                return 'Crear tarea e iniciar';
            default:
                return 'Iniciar contador';
        }
    }

    const handleSetPadres = () => {
        onSetNombreCliente(nombreCli);
        onSetNombreProyecto(nombrePro);
    }

    useEffect(() => {
        if (selected && !mouseFocus && scrollEnabled) {
            node.current.scrollIntoView();
            handleSetPadres();
        }
    }, [selected])

    const _mouseEnter = () => {
        setMouseFocus(true);
        onSetCurrentItem(item.id);
        handleSetPadres();
        onMouseEnter && onMouseEnter();
    };

    const _mouseLeave = () => setMouseFocus(false);

    const wrapperClass = 'row resultItem' + (selected ? ' selected' : '');

    return (
        <div className={wrapperClass} onMouseEnter={_mouseEnter} ref={node} onMouseLeave={_mouseLeave} >
            <div className="col">
                <div className="temporizadorItem" onClick={() => onAction(item)}>
                    <div className="item-name" dangerouslySetInnerHTML={{ __html: item.nombre }}>
                    </div>
                    <div className="item-actions">
                        {selected && handleActionType(item.tipo)}
                    </div>
                </div>

            </div>
        </div>

    )
};

Item.defaultProps = {
    scrollEnabled: true
}

Item.propTypes = {
    item: PropTypes.object.isRequired,
    scrollEnabled: PropTypes.bool.isRequired,
    onAction: PropTypes.func.isRequired,
    selected: PropTypes.bool.isRequired,
    onSetCurrentItem: PropTypes.func.isRequired,
    onMouseEnter: PropTypes.func.isRequired
}

export default Item;
