import React, { useState, useEffect } from 'react';
import Modal from '../Modal';
import PropTypes from 'prop-types';
import axios from "axios";
import { API_URL } from '../../constants';
import Util from './util';

import List from './List';
import SearchForm from './searchForm';
import ProyectoTareaForm from './proyectoTareaForm';
import TareaForm from './tareaForm';

let updater = false;
const Iniciar = ({ onHide, show, onSetWorking, onSetProyecto, onSetTarea, onSetInicio }) => {

    const [searched, setSearched] = useState(false);
    const [id, setId] = useState();
    const [tipo, setTipo] = useState();
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentItem, setCurrentItem] = useState(false);
    const [totalItems, setTotalItems] = useState(0);
    const [nombreProyecto, setNombreProyecto] = useState('');
    const [nombreCliente, setNombreCliente] = useState('');

    const handleClose = () => {
        setData([]);
        setId();
        setTipo();
        setSearched(false);
        setSearchTerm('');
        setCurrentItem(false);
        setTotalItems(0);
        onHide();
        setNombreProyecto('');
        setNombreCliente('');
    }

    const handleBack = () => {
        setTipo();
        setId();
    }

    const handleKeyDown = e => {
        let item = false;

        // si hay uno marcado y se pulsa enter
        if (currentItem !== false && e.keyCode === 13) {
            item = Util.f_getTareaItem(data, currentItem);
            handleAction(item);
            e.preventDefault();
        }

        // up and down
        if (e.keyCode === 38 || e.keyCode === 40) {
            // si no hay current item, marcamos el primero
            if (!currentItem)
                item = Util.f_getFirstTareaID(data);
            else {
                if (e.keyCode === 38)
                    item = Util.f_getNextPreviewIDTarea(data, currentItem, false);
                else
                    item = Util.f_getNextPreviewIDTarea(data, currentItem);
            }
        }

        if (item !== false)
            setCurrentItem(item);
    }

    const handleFetchData = async (search) => {
        const url = API_URL + 'api/buscador?query=' + search;
        setCurrentItem(false);
        setTotalItems(0);
        axios.get(url)
            .then(response => {
                setData(response.data);
                setSearched(true);

            })
            .catch(error => {
                console.log(error);
            })

    };

    useEffect(() => {

        if (data.length > 0 && totalItems === 0) {
            setTotalItems(Util.f_getTotalItems(data));
        }

    }, [data]);

    const handleChangeSearchTermInput = e => {

        setNombreCliente('');
        setNombreProyecto('');
        console.log(!e.target.value || e.target.value === searchTerm);
        if (!e.target.value || e.target.value === searchTerm) {
            setData([]);
            return;
        } else {
            let search = e.target.value;

            setSearchTerm(e.target.value);

            if (updater !== false)
                clearTimeout(updater);

            updater = setTimeout(() => {
                handleFetchData(search);
            }, 200);
        }

    }

    const handleAction = item => {
        switch (item.tipo) {
            case 'tarea':
                axios
                    .get(API_URL + 'api/tiempos/iniciar-tarea?id=' + item.id)
                    .then(response => {

                        if (response.data.status) {
                            window.toastr.success('La tarea ha sido iniciada correctamente.', 'Correcto', {
                                closeButton: true,
                                progressBar: true,
                                timeOut: 5000,
                                extendedTimeOut: 1500
                            });
                            onSetTarea(response.data.tarea);
                            onSetProyecto(response.data.proyecto);
                            onSetInicio(response.data.inicio);
                            handleClose();
                            onSetWorking(true);
                        }

                        if (!response.data.status) {
                            window.toastr.error(response.data.data, 'Error', {
                                closeButton: true,
                                progressBar: true,
                                timeOut: 5000,
                                extendedTimeOut: 1500
                            });
                        }
                    })
                    .catch(e => {
                        console.log(e)
                    });
                return;
            default:
                setId(item.id);
                setTipo(item.tipo);
                return;
        }

    }

    let modalTitle = 'Iniciar temporizador';
    if (id !== undefined && tipo !== undefined) {
        if (tipo === 'cliente')
            modalTitle = 'Crear proyecto y tarea e iniciar';
        else
            modalTitle = 'Crear tarea e iniciar';
    }

    return (
        <Modal
            show={show}
            onHide={handleClose}
        >

            <div className="card-header">
                <strong>{modalTitle}</strong>
            </div>

            <div className="card-body">
                {id !== undefined && tipo !== undefined ? (
                    <>
                        {
                            tipo === 'cliente' ? (
                                <ProyectoTareaForm
                                    onBack={handleBack}
                                    id={id}
                                />
                            ) : (
                                    <TareaForm
                                        onBack={handleBack}
                                        id={id}
                                        onAction={handleAction}
                                    />
                                )
                        }
                    </>
                ) : (
                        <>
                            <div className="row">
                                <div className="col">
                                    <SearchForm
                                        searchTerm={searchTerm}
                                        searched={searched}
                                        onKeyDown={handleKeyDown}
                                        onChangeSearchTermInput={handleChangeSearchTermInput}
                                        nombreCliente={nombreCliente}
                                        nombreProyecto={nombreProyecto}
                                    />
                                </div>
                            </div>
                            {searched && (

                                data.length > 0 ? (
                                    <List
                                        data={data}
                                        onAction={handleAction}
                                        total={totalItems}
                                        currentItem={currentItem}
                                        onSetCurrentItem={setCurrentItem}
                                        onSetNombreCliente={setNombreCliente}
                                        onSetNombreProyecto={setNombreProyecto}
                                        onSetId={setId}
                                        onSetTipo={setTipo}
                                    />
                                ) :
                                    (
                                        <>
                                            <div className="row">
                                                <div className="col">
                                                    <h4>Sin resultado</h4>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <p>No se ha encontrado ningún resultado.</p>
                                                </div>
                                            </div>
                                        </>
                                    )
                            )}
                        </>
                    )}

            </div>

        </Modal>
    );
};

Iniciar.propTypes = {
    show: PropTypes.bool.isRequired,
    onSetWorking: PropTypes.func.isRequired,
    onSetProyecto: PropTypes.func.isRequired,
    onSetTarea: PropTypes.func.isRequired,
    onSetInicio: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired,
}

export default Iniciar;
