import React from 'react';
import PropTypes from 'prop-types';

import SortableItem from './SortableItem';

const SortableList = props => {

    return (
        <SortableItem
            key={`l${props.i}i${props.list.id}`}
            index={props.i}
            lista={props.list}
            onUpdateProperty={props.onUpdateProperty}
        />
    );
};

SortableList.propTypes = {
    i: PropTypes.number.isRequired,
}

export default SortableList;