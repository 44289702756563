import React, { useContext } from 'react';
import Comentario from "./Comentario";
import { ComentariosContext } from "../../context/ComentariosContext";
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import './style.css';
const List = props => {

    const { items } = useContext(ComentariosContext);

    return (<div className="comment_list row"><div className="col">

        {items && items.length > 0 ? (
            <TransitionGroup>
                {items.map((comentario, idx) => {
                    return (
                        <CSSTransition
                            key={idx}
                            timeout={1500}
                            classNames="item"
                        >
                            <Comentario
                                comentario={comentario}
                            />
                        </CSSTransition>
                    )
                })}
            </TransitionGroup>
        ) : (
                <div className="row">
                    <div className="col-md-12" align="center">
                        <p><strong>Sin comentarios</strong></p>
                    </div>
                </div>
            )}

    </div></div>);
}


export default List
