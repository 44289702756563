import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import arrayMove from 'array-move';
import SortableListasTareas from './SortableListasTareas';
import TareaDetalle from '../Tarea/detalle';
import Spinner from '../Spinner';
import Filtro from './filtro';
import { API_URL } from '../../constants';
import filterUtil from '../../util/filter';
import './style.scss';

import Tarea from '../../util/tarea';

import {
    BrowserRouter as Router,
    Switch,
    Route,
} from 'react-router-dom';

axios.defaults.withCredentials = true;

const Proyecto = (props) => {

    const LS_FILTER_KEY = 'proyectos_filter';
    const SHOW_TITLE_TAG = 'title_tag';

    const filterDefaultValues = {
        name: '',
        finalizado: false,
    }

    const pid = props.pid;
    const [loaded, setLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const [itemsFiltered, setItemsFiltered] = useState([]);
    const [filter, setFilter] = useState(filterUtil.getDefaultFilterValues(LS_FILTER_KEY, filterDefaultValues));
    const [showTagTitle, setShowTagTitle] = useState(filterUtil.getDefaultTitleTag(SHOW_TITLE_TAG));

    const handleSetTitle = v => {
        setShowTagTitle(v);
        localStorage.setItem(SHOW_TITLE_TAG, JSON.stringify(v))
    }

    useEffect(() => {
        localStorage.setItem(LS_FILTER_KEY, JSON.stringify(filter))
        handleFilter()
    }, [filter, items])

    useEffect(() => {
        if (pid !== undefined) {
            const url = API_URL + 'api/proyectos/tareas-con-lista?id=' + pid;
            axios.get(url)
                .then(response => {
                    setItems(response.data);
                    setLoaded(true);
                })
                .catch(error => {
                    // console.log(error);
                })
        }

    }, []);

    const setFilterName = name => {
        setFilter({ ...filter, name });
    }

    const setFilterFinalizado = finalizado => {
        setFilter({ ...filter, finalizado });
    }

    /**
     * Si action es true, hacemos la acción de añadir al array items. Create
     * Si action es false, hacemos la acción de actualizar una tarea. Update
     * @param {object} tarea
     * @param {string} action
     */
    const handleAddOrUpdateItem = (tarea, action) => {
        let ar = [];

        items.map(lista => {
            let tareas = lista.items;
            let l = lista.lista;

            switch (action) {
                case 'create':
                    if (l !== null && l.id === tarea.lista_id) {
                        tareas.push(tarea);
                    } else if (l === null && tarea.lista_id === null) {
                        tareas.push(tarea);
                    }

                    break;
                case 'update':
                    tareas.map(tar => {
                        if (tarea.id === tar.id) {
                            return tar = tarea;
                        }
                        return tar;
                    })
                    break;
                default:
                    console.log('default');
            }

            let res = {
                lista: l,
                items: tareas
            }

            return ar.push(res);
        });

        setItems(ar);
    }

    const resetFilter = () => {
        setFilter(filterDefaultValues);
        localStorage.setItem(LS_FILTER_KEY, JSON.stringify(filter))
    }

    const handleFilter = () => {
        let ar = [];
        if (items === null) return;
        const finalizado = filter.finalizado ? 1 : 0

        if (items.length > 0) {
            items.map(lista => {
                let tareas = lista.items.filter(tarea =>
                    tarea.nombre.toLowerCase().includes(filter.name.toLowerCase().trim()) &&
                    (tarea.finalizar === finalizado || tarea.finalizar === 0)
                );

                let res = {
                    lista: lista.lista,
                    items: tareas
                }
                return ar.push(res);
            });

            setItemsFiltered(ar);
        }
    }

    const getFilteredArray = (original) => {
        let ar = [];

        const finalizado = filter.finalizado ? 1 : 0

        if (original.length > 0) {
            original.map(lista => {
                let tareas = lista.items.filter(tarea =>
                    tarea.nombre.toLowerCase().includes(filter.name.toLowerCase().trim()) &&
                    (tarea.finalizar === finalizado || tarea.finalizar === 0)
                );

                let res = {
                    lista: lista.lista,
                    items: tareas
                }
                return ar.push(res);
            });

            return ar;
        }
    }

    const handleSortEnd = ({ oldIndex, newIndex }, i, newList, order) => {
        const idL = items[i].lista !== null ? items[i].lista.id : null;

        //Si idL(lista actual de la tarea), es distinta de newList, cambiamos la lista.
        if (idL !== newList) {
            handleSetNewListAndOrder(oldIndex, newList, order, i);
        }
        else
            handleSetIndexItem(oldIndex, newIndex, i);

    };

    const handleSetNewListAndOrder = (oldIndex, newList, order, i) => {
        const ar = [];

        //Busco la tarea
        const tarea = itemsFiltered[i].items[oldIndex];

        // Seteo la nueva fila
        let newListIndex = null;

        //Rellenamos un nuevo array sin posicionar la tarea en ninguna lista
        items.map((lista, index) => {
            //newListIndex es igual al índice de la nueva lista en el array itemsFiltered
            if (
                (lista.lista !== null && lista.lista.id === newList) ||
                (lista.lista === null && newList === null)
            )
                newListIndex = index;

            let tareas = lista.items.filter(t => t.id !== tarea.id);
            let l = lista.lista;

            let res = {
                lista: l,
                items: tareas
            }

            return ar.push(res);
        });
        //Actualizo el array con las listas e items añadiendo la tarea en la nueva lista
        ar[newListIndex].items.push(tarea);
        let arFiltered = getFilteredArray(ar);

        const size = arFiltered[newListIndex].items.length;
        if (size > 1) {
            let oldId = arFiltered[newListIndex].items[size - 1].id;
            let newId = arFiltered[newListIndex].items[order].id;
            let oldPos = ar[newListIndex].items.findIndex(tarea => tarea.id === oldId);
            let newPos = ar[newListIndex].items.findIndex(tarea => tarea.id === newId);
            let res = arrayMove(ar[newListIndex].items, oldPos, newPos);
            ar[newListIndex].items = res;
        }
        
        setItems(ar);
        //Actualizo la tarea con los nuevos datos

        tarea['lista_id'] = newList;
        Tarea.update(tarea).then((value) => {
            if (value.status) {
                //Actualizo el listado
                let tareas = ar[newListIndex].items.map((tarea, i) => tarea.id);
                handleSortLista(tareas);
            }
        })
        //Fin actualizar tarea
    }

    const handleSetIndexItem = (oldIndex, newIndex, i) => {

        setItems(items.map((item, ii) => {
            if (i === ii) {
                const elemento = itemsFiltered[i];
                let oldId = elemento.items[oldIndex].id;

                let newId = elemento.items[newIndex].id;

                //oldIndex tiene que ser el viejo índice en el array sin filtrar
                let oldPos = item.items.findIndex(tarea => tarea.id === oldId);

                //newIndex tiene que ser el nuevo índice en el array sin filtrar
                let newPos = item.items.findIndex(tarea => tarea.id === newId);

                item.items = arrayMove(item.items, oldPos, newPos);

                let tareas = item.items.map((tarea, i) => tarea.id);
                handleSortLista(tareas);

                return item;
            }
            else
                return item
        }))
    }

    const handleSortLista = (tareas) => {
        const url = API_URL + 'api/tareas/ordenar';
        const params = new URLSearchParams();
        params.append('orden', tareas);

        axios({
            method: 'post',
            url,
            data: params
        }).then(d => {
            // console.log('actualizado');
        }).catch(e => {
            window.toastr.error('Error al mover la tarea', 'Error', {
                closeButton: true,
                progressBar: true,
                timeOut: 5000,
                extendedTimeOut: 1500
            });
        })
    }

    return (
        <>
            {loaded ? (
                <Router>
                    <Switch>
                        <Route path="/proyecto/detalle/:id">
                            <Filtro
                                filterName={filter.name}
                                onSetFilterName={setFilterName}
                                filterFinalizado={filter.finalizado}
                                onSetFilterFinalizado={setFilterFinalizado}
                                onResetFilter={resetFilter}
                            />
                            <SortableListasTareas items={itemsFiltered}
                                onSortEnd={handleSortEnd}
                                pid={pid}
                                showTagTitle={showTagTitle}
                                onSetTitle={v => handleSetTitle(v)}
                                onAddItem={v => handleAddOrUpdateItem(v, 'create')}
                            />
                        </Route>
                    </Switch>

                    <Switch>
                        <Route path="/proyecto/detalle/:id/tarea/:tarea_id" exact>
                            <TareaDetalle
                                items={itemsFiltered}
                                filterFinalizado={filter.finalizado}
                                showTagTitle={showTagTitle}
                                onSetTitle={v => handleSetTitle(v)}
                                onUpdate={v => handleAddOrUpdateItem(v, 'update')}
                            />
                        </Route>
                    </Switch>
                </Router>
            ) : (
                    <Spinner />
                )
            }

        </>
    )
};

Proyecto.propTypes = {
    pid: PropTypes.string.isRequired,
}

export default Proyecto;
