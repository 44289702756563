import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

const SearchForm = (props) => {
    let input = useRef(null);

    useEffect(() => {
        input.current.focus();
    }, [])

    return (
        <form>
            <div className="form-group search-form">
                <div className="nombre-field">
                    <div className="row">
                        <div className="col-md-12">
                            <label className="form-label">Cliente, proyecto o tarea</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <input
                                className="form-control"
                                defaultValue={props.searchTerm}
                                ref={input}
                                autoComplete="off"
                                onKeyDown={props.onKeyDown}
                                onChange={e => props.onChangeSearchTermInput(e)}
                            />
                            <div
                                className='parents'
                            >
                                {props.nombreCliente} {props.nombreCliente.length > 0 && '-'}  {props.nombreProyecto}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>

    );
};

SearchForm.propTypes = {
    searchTerm: PropTypes.string,
    searched: PropTypes.bool.isRequired,
    onKeyDown: PropTypes.func.isRequired,
    currentItem: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool
    ]),
    onChangeSearchTermInput: PropTypes.func.isRequired
}

export default SearchForm;
