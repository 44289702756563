import React, { useEffect, useState } from 'react';
import Comentarios from '../Comentarios';
import dateUtil from '../../util/date';
import {
    withRouter,
    useParams
} from 'react-router-dom';
import Header from './Header';
import BotonesAccion from './BotonesAccion';
import Propiedades from './Propiedades';
import Tarea from '../../util/tarea';
import { TareaDetalleContext } from "../../context/TareaDetalleContext";
import './style.scss';

const Detalle = props => {

    const { id, tarea_id } = useParams();
    const [tarea, setTarea] = useState();
    const [creating, setCreating] = useState(false);
    const [mouseOnModal, setMouseOnModal] = useState(true);
    const [PrevItem, setPrevItem] = useState(false);
    const [NextItem, setNextItem] = useState(false);

    useEffect(() => {
        const keyPressHandler = (e) => {
            if (e.keyCode === 27)
                props.history.push(`/proyecto/detalle/${id}`)
        };

        document.addEventListener('keydown', keyPressHandler);
        return () => {
            document.removeEventListener('keydown', keyPressHandler);
        };
    }, []);

    useEffect(() => {
        let t = false;
        let coincidence = false;
        for (const l of props.items) {
            t = l.items.find((item, i) => {
                if (item.id === parseInt(tarea_id)) {
                    let prev = l.items[i - 1];
                    let next = l.items[i + 1];
                    if (prev !== undefined)
                        setPrevItem(prev);
                    if (next !== undefined)
                        setNextItem(next)
                    return true;
                }
                return false;
            });
            if (t) {
                setTarea(t);
                coincidence = true;
            }
        }

        if (!coincidence)
            props.history.push(`/proyecto/detalle/${id}`);
    }, [props.history, props.items, id, tarea_id])

    const updateProperty = (valor, propiedad) => {
        console.log('Update property');
        if (propiedad === 'hijas') {
            tarea[propiedad].push(valor);
            tarea['esPadre'] = true;
        }
        else
            tarea[propiedad] = valor;

        if (propiedad === 'finalizar') {
            //Si hay nextItem y estamos filtrando por no finalizados
            if (NextItem !== false && !props.filterFinalizado)
                props.history.push(`/proyecto/detalle/${id}/tarea/${NextItem.id}`)
            console.log('organizar el array si no estamos filtrando por finalizados y mostrar la siguiente tarea activa');
        }
        
        tarea['fecha_modificacion'] = dateUtil.getNowToString();

        
        Tarea.update(tarea).then((value) => {
            if (value.status) {
                if ((value.response.data !== undefined || value.response.data !== null) && props.onUpdate !== undefined) {
                    // value.response.data.fecha_modificacion = dateUtil.toUTC(value.response.data.fecha_modificacion);
                    // console.log(value.response.data);
                    props.onUpdate(value.response.data);
                    setCreating(false);
                }
            } else {
                window.toastr.error('Error al modificar la tarea', 'Error', {
                    closeButton: true,
                    progressBar: true,
                    timeOut: 5000,
                    extendedTimeOut: 1500
                });
            }
        })
    }

    return (
        <>
            {
                tarea !== undefined && (
                    <div className="tareaModalOverlay" onClick={() => {
                        if (!mouseOnModal)
                            props.history.push(`/proyecto/detalle/${id}`)
                    }}>
                        <div className="tareaModalContent" onMouseEnter={() => setMouseOnModal(true)} onMouseLeave={() => setMouseOnModal(false)}>
                            <TareaDetalleContext.Provider
                                value={{
                                    tarea,
                                    setTarea,

                                    creating,
                                    setCreating,

                                    updateProperty,

                                    PrevItem,
                                    setPrevItem,

                                    NextItem,
                                    setNextItem,
                                }}>
                                <Header
                                    history={props.history}
                                    onSetTitle={props.onSetTitle}
                                    showTagTitle={props.showTagTitle}
                                />

                                <div className="main_content row">

                                    <Comentarios className="col col-md-6 comments" tipo={1} codigo={tarea.id} />

                                    <section className="col col-md-6 has-scroll">
                                        <BotonesAccion />
                                        <Propiedades
                                            onUpdate={props.onUpdate}
                                        />
                                    </section>
                                </div>
                            </TareaDetalleContext.Provider>

                        </div>
                    </div>
                )
            }
        </>
    );
};

export default withRouter(Detalle);
