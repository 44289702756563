import React, { useContext } from 'react';
import SortableContainer from './SortableContainer';
import SortableList from './SortableList';
import dateUtil from '../../util/date';
import { API_URL } from '../../constants'
import axios from 'axios';
import arrayMove from 'array-move';
import { nonUndefined } from '../../util';
import { TareaDetalleContext } from "../../context/TareaDetalleContext";

const Propiedades = (props) => {

    const { tarea, setTarea } = useContext(TareaDetalleContext);

    const handleSortEnd = ({ oldIndex, newIndex }) => {
        if (oldIndex === newIndex) return;
        console.log('sort hijas');
        tarea.hijas = arrayMove(tarea.hijas, oldIndex, newIndex);
        let orden = [];
        tarea.hijas.map((item) => orden.push(item.id));

        const url = API_URL + 'api/tareas/ordenar';
        const params = new URLSearchParams();
        params.append('orden', orden);

        axios({
            method: 'post',
            url,
            data: params
        }).then(d => {
            console.log(orden);
        }).catch(e => {
            console.log(e);
        })


        setTarea(tarea);
        props.onUpdate(tarea);
    }

    return (
        <div className="detalles">
            <table className="table table-bordered table-striped">
                <tbody>
                    <tr>
                        <th scope="col">Fecha de creación:</th>
                        <td>{nonUndefined(dateUtil.toUTC(tarea.fecha_creacion), 'fecha de creación')}</td>
                    </tr>
                    <tr>
                        <th scope="col">Última fecha de modificación:</th>
                        <td>{nonUndefined(dateUtil.toUTC(tarea.fecha_modificacion), 'fecha de modificación')}</td>
                    </tr>
                    <tr>
                        <th scope="col">Proyecto:</th>
                        <td>{nonUndefined(tarea.proyecto_nombre, 'proyecto')}</td>
                    </tr>
                    <tr>
                        <th scope="col">Milestone:</th>
                        <td>{nonUndefined(tarea.milestone_nombre, 'milestone')}</td>
                    </tr>
                    <tr>
                        <th scope="col">Lista:</th>
                        <td>{nonUndefined(tarea.lista_nombre, 'lista')}</td>
                    </tr>
                    <tr>
                        <th scope="col">Tiempo estimado:</th>
                        <td>{nonUndefined(tarea.estimado, 'tiempo estimado')}</td>
                    </tr>
                </tbody>
            </table>

            <div className="tarea-descripcion">
                <p><strong>Descripción:</strong></p>
                <p>
                    {nonUndefined(tarea.descripcion, 'descripción')}
                </p>
            </div>

            <div className="detalles-tarea">

                {tarea.esPadre && (
                    <div className="section tareas_hijas">
                        <div className="row">
                            <div className="col-md-12">
                                <span className="label">Tareas hijas:</span>
                                {tarea.hijas.length > 0 ? (
                                    <SortableContainer lockAxis="y" onSortEnd={(a) => handleSortEnd(a)} useDragHandle>
                                        {tarea.hijas.map((hija, i) =>
                                            <SortableList key={i} i={i} list={hija} />
                                        )}
                                    </SortableContainer>
                                ) : (
                                        nonUndefined(tarea.hijas, 'tareas hijas')
                                    )
                                }
                            </div>
                        </div>
                    </div>
                )}
                {!tarea.esHijo && tarea.tarea_id !== null && (
                    <div className="row">
                        <div className="col-md">
                            <span className="label">Tarea padre:</span> <span className="result">{tarea.tarea_id}</span>
                        </div>
                    </div>
                )}

            </div>
        </div>
    );
}

export default Propiedades;