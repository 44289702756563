import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ListSection from "./ListSection";

const List = (props) => {

    const [scrollEnabled, setScrollEnabled] = useState(true);

    return (
        <>
            <div className="buscador_res">

                        <div className="wrapper"
                            onMouseLeave={() => setScrollEnabled(true)}
                            onMouseEnter={() => setScrollEnabled(false)}
                        >
                            {props.data.map((item, i) =>

                                <ListSection
                                    key={i}
                                    idCli={item.id}
                                    nombreCli={item.nombre}
                                    proyectos={item.proyectos}
                                    scrollEnabled={scrollEnabled}
                                    currentItem={props.currentItem}
                                    onSetCurrentItem={props.onSetCurrentItem}
                                    onAction={props.onAction}
                                    onSetId={props.onSetId}
                                    onSetTipo={props.onSetTipo}
                                    onSetNombreCliente={props.onSetNombreCliente}
                                    onSetNombreProyecto={props.onSetNombreProyecto}
                                    onMouseEnter={() => { props.onCurrentItemChange && props.onCurrentItemChange(i) }}
                                />
                            )}
                        </div>
            </div>
        </>
    )
};

List.propTypes = {
    data: PropTypes.array.isRequired,
    onAction: PropTypes.func.isRequired,
    total: PropTypes.number.isRequired,
    currentItem: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool
    ]).isRequired,
    onSetCurrentItem: PropTypes.func.isRequired,
    onSetId: PropTypes.func.isRequired,
    onSetTipo: PropTypes.func.isRequired,
    onCurrentItemChange: PropTypes.func,
}

export default List;
