const getDefaultFilterValues = (filterKey, filterDefaultValues) => {
    
    let filterValues = {}
    try {
        const prevFilter = JSON.parse(localStorage.getItem(filterKey));
        filterValues = {...filterDefaultValues, ...prevFilter}
    } catch(err) {
        filterValues =  filterDefaultValues
    }

    return filterValues
}

const getDefaultTitleTag = (filterKey, defaultValue = false) => {
    
    let filterValues = {}
    try {
        filterValues = JSON.parse(localStorage.getItem(filterKey));
    } catch(err) {
        filterValues =  defaultValue;
    }

    return filterValues
}

export default {
    getDefaultFilterValues,
    getDefaultTitleTag
}