import React, { useState, useEffect } from 'react';
import Contador from './contador';
import Iniciar from './iniciar';
import Detener from './detener';

import './style.scss';

const Temporizador = props => {

    const [working, setWorking] = useState();
    const [show, setShow] = useState(false);
    const [proyecto, setProyecto] = useState();
    const [tarea, setTarea] = useState();
    const [inicio, setInicio] = useState();
    const [timing, setTiming] = useState();
    const [open, setOpen] = useState(false);
    const [intId, setIntId] = useState();

    const daysBetween = (ahora, inicio) => {
        let sec_num = (ahora - inicio) / 1000;

        let days = Math.floor(sec_num / (3600 * 24));
        let hours = Math.floor((sec_num - (days * (3600 * 24))) / 3600);
        let minutes = Math.floor((sec_num - (days * (3600 * 24)) - (hours * 3600)) / 60);
        let seconds = Math.floor(sec_num - (days * (3600 * 24)) - (hours * 3600) - (minutes * 60));
        hours = (days * 24) + hours;
        if (hours < 10) { hours = "0" + hours; }
        if (minutes < 10) { minutes = "0" + minutes; }
        if (seconds < 10) { seconds = "0" + seconds; }
        return hours + ':' + minutes + ':' + seconds;
    }

    const handleStop = () => {
        setWorking(false);
        window.working = false;
        setProyecto();
        setTarea();
        setInicio();
        setOpen(false);
        setShow(false);
    }

    useEffect(() => {
        if (working === undefined){
            setWorking((props.working === 'true'));
            window.working = props.working === 'true';
        }

        if ((props.working === 'true') && working === undefined) {
            const proyecto = {
                id: props.proid,
                nombre: props.pronom
            };

            const tarea = {
                id: props.tarid,
                nombre: props.tarnom
            };
            setProyecto(proyecto);
            setTarea(tarea);
            setInicio(props.inicio);
        }

    }, [props])

    useEffect(() => {

        if (inicio !== undefined) {
            let date_inicio = new Date(inicio.replace(/-/g, "/"));
            let ahora = new Date();
            setOpen(true);
            setTiming(daysBetween(ahora, date_inicio));

            let id = setInterval(() => {
                ahora = new Date();
                date_inicio = new Date(inicio.replace(/-/g, "/"));
                setTiming(daysBetween(ahora, date_inicio));
                setIntId(id);
            }, 1000);
        }

    }, [inicio]);

    useEffect(() => {
        if (!working && intId !== undefined) {
            clearInterval(intId);
        }
    }, [working, intId]);

    return (
        <div className="temporizadorComponent">
            <button
                onClick={() => setShow(!show)}
                className="nav-item px-3 link-button btn-temporizador"
            >
                {working ? <span className="btn-text"> Detener Temporizador</span> : <span className="btn-text"> Iniciar Temporizador</span>}
            </button>

            {working ?
                <>
                    <Detener
                        show={show}
                        onStop={handleStop}
                        tnombre={tarea.nombre}
                        onHide={() => setShow(false)}
                    />

                    {/*<Contador /> es un portal que va a mostrarse en el cuerpo de la página*/}
                    <Contador
                        proyecto={proyecto}
                        tarea={tarea}
                        inicio={inicio}
                        timing={timing}
                        open={open}
                    />
                </>
                :
                <Iniciar
                    show={show}
                    onSetWorking={setWorking}
                    onSetProyecto={setProyecto}
                    onSetTarea={setTarea}
                    onSetInicio={setInicio}
                    onHide={() => setShow(false)}
                />

            }
        </div>
    );
};

export default Temporizador;
