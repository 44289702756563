import React, { useState, useContext } from 'react';
import Form from './FormUpdate';
import ReactMarkdown from "react-markdown";
import { ComentariosContext } from "../../context/ComentariosContext";

const Comentario = ({ comentario }) => {

    const { utoken } = useContext(ComentariosContext);

    const [editing, setEditing] = useState(false);

    const handleEdit = () => {
        if (comentario.token === utoken)
            setEditing(true);
    }

    return (<div id="observaciones_widget_listado" className="observaciones_widget_listado_row row">
        <div className="observaciones_widget_listado_single col-md-12">

            <div className="row">
                <div className="col-md-12">
                    {editing ? (
                        <Form
                            setEditing={setEditing}
                            comentario={comentario}
                            editing={editing}
                        />
                    ) : (
                            <div onClick={() => { handleEdit(); }}>
                                <ReactMarkdown
                                    source={comentario.texto}
                                    escapeHtml={false}
                                />
                            </div>
                        )}
                </div>

            </div>

            <div className="row metadata">
                <div className="col-md-6">{comentario.usuario}</div>
                <div className="col-md-6 text-right">{comentario.fecha}</div>
            </div>

        </div>
    </div>);
}

export default Comentario;
