import React, {Component} from 'react';

export default class Error extends Component {

    render() {
        return (
            <>
                <div className="alert alert-error">
                   Tu petición no se ha podido procesar. Recarga la página e inténtalo de nuevo.
                </div>
            </>
        )
    }
}
