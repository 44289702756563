import React from 'react';
import PropTypes from 'prop-types';

import SortableItem from './SortableItem';

const SortableList = props => {

    return (
        props.list.items.map((tarea, index) =>
            <SortableItem
                showTagTitle={props.showTagTitle}
                onSetTitle={props.onSetTitle}
                key={`l${props.i}i${index}`}
                onSetNewOrder={() => props.onSetOrder(index)}
                index={index} tarea={tarea}
            />
        )
    );
};

SortableList.propTypes = {
    i: PropTypes.number.isRequired,
    list: PropTypes.object.isRequired,
}

export default SortableList;
