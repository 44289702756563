import React, { useState } from 'react';
import PropTypes from 'prop-types';

import SortableContainer from './SortableContainer';
import SortableList from './SortableList';

import TareaCreate from '../Tarea/TareaCreate';

const SortableListasTareas = props => {

    const [creating, setCreating] = useState(false);
    const [idList, setIdList] = useState();
    const [currentList, setCurrentList] = useState();
    const [order, setOrder] = useState();

    const handleCreate = tarea => {
        props.onAddItem(tarea);
        setCreating(false);
    }

    const handleCanCreate = item => {
        return creating && ((item.lista !== null && idList === item.lista.id) || (item.lista === null && idList === null));
    }

    return (
        props.items.map((item, i) =>
            <div
                key={i}
                className="list list-sortable"
                onMouseEnter={() => { 
                    setCurrentList(item.lista !== null ? item.lista.id : null);
                    setOrder(0);
                }}
            >
                <h2>{item.lista ? item.lista.nombre : 'Sin lista'}</h2>

                <SortableContainer
                    lockAxis="y"
                    onSortEnd={(a) => props.onSortEnd(a, i, currentList, order)}
                    useDragHandle
                >

                    <SortableList
                        i={i}
                        list={item}
                        onSetOrder={v => setOrder(v)}
                        showTagTitle={props.showTagTitle}
                        onSetTitle={props.onSetTitle}
                    />

                </SortableContainer>
                {handleCanCreate(item) ? (
                    <TareaCreate
                        proyecto_id={props.pid}
                        lista_id={idList}
                        onClose={() => setCreating(false)}
                        onCreate={handleCreate}
                    />
                ) : (
                        <button
                            className="btn btn-link"
                            onClick={() => {
                                let id = item.lista !== null ? item.lista.id : null;
                                setCreating(true);
                                setIdList(id);
                            }}
                        >
                            Crear tarea
                        </button>
                    )}
            </div>)
    )
};

SortableListasTareas.propTypes = {
    items: PropTypes.array.isRequired,
    onSortEnd: PropTypes.func.isRequired,
}

export default SortableListasTareas;
