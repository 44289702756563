import React, { useContext } from 'react';
import UserList from '../User/List';
import {
    useParams
} from 'react-router-dom';
import EditableInput from '../EditableInput';
import TagList from '../Tag/List';
import { TareaDetalleContext } from "../../context/TareaDetalleContext";

const Header = (props) => {


    const { tarea, updateProperty, PrevItem, setPrevItem, NextItem, setNextItem } = useContext(TareaDetalleContext);
    const { id } = useParams();

    const handleGoPrev = (e) => {
        e.preventDefault();
        if (PrevItem !== false)
            props.history.push(`/proyecto/detalle/${id}/tarea/${PrevItem.id}`)
        setPrevItem(false);
    }

    const handleGoNext = (e) => {
        e.preventDefault();
        if (NextItem !== false)
            props.history.push(`/proyecto/detalle/${id}/tarea/${NextItem.id}`)
        setNextItem(false);
    }

    return (
        <header>
            <div className="row">
                <div className="col-md-12">
                    <div className="btn-close">
                        <span onClick={() => props.history.push(`/proyecto/detalle/${id}`)} className="close">X</span>
                    </div>
                    
                    <div className="arrows">
                        <button
                            onClick={handleGoPrev}
                            className="btn btn-link btn-prev"
                            disabled={PrevItem === false}
                        >
                            <i data-toggle="tooltip" title="Anterior" className="arrows fa fa-angle-double-left fa-3x"></i>
                        </button>
                        <button
                            onClick={handleGoNext}
                            className="btn btn-link btn-next"
                            disabled={NextItem === false}
                        >
                            <i data-toggle="tooltip" title="Siguiente" className="fa fa-angle-double-right fa-3x"></i>
                        </button>
                    </div>

                    <div className="tarea-nombre">
                        <h1>
                            {tarea.finalizar === 1 && <i title="Tarea finalizada" data-toggle="tooltip" className="tarea-finalizada fa fa-info-circle">&nbsp;</i>}

                            <span className="id-tarea-detalle text-muted"><small>#{tarea.id}</small></span>
                        </h1>
                        <h1>
                            <EditableInput
                                value={tarea.nombre}
                                property={'nombre'}
                                onChange={v => updateProperty(v, 'nombre')}
                            />
                        </h1>
                    </div>

                    <div className="subheader">
                        <div className="tarea-detalle-tags">
                            {tarea.tags.length > 0 &&

                                <div className="tags">
                                    <TagList
                                        showTagTitle={props.showTagTitle}
                                        onSetTitle={props.onSetTitle}
                                        items={tarea.tags}
                                    />
                                </div>

                            }
                        </div>
                        <div className="tarea-detalle-users">
                            {tarea.users.length > 0 &&
                                <UserList items={tarea.users} />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header;