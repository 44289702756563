import React from 'react';
import PropTypes from 'prop-types';
import {
    withRouter,
    useRouteMatch,
    Link,
} from 'react-router-dom';

import { sortableElement, sortableHandle } from 'react-sortable-hoc';

import TagList from '../Tag/List';
import UserList from '../User/List';
import './style.scss';

const DragHandle = sortableHandle(() => <i className="icon-menu sortable" />);

const SortableItem = sortableElement((props) => {

    const { url: currentUrl } = useRouteMatch();

    return (
        <li>
            <div onMouseOver={() => props.onSetNewOrder()} data-id={props.tarea.id} className={'list-item draggableItem ' + (props.tarea.finalizar === 1 ? 'done' : '')}>
                <div className="list-item-body">
                    <DragHandle />
                    <Link to={`${currentUrl}/tarea/${props.tarea.id}`}>
                        {props.tarea.id} {props.tarea.nombre}
                    </Link>


                </div>
                <footer>

                    {props.tarea.tags.length > 0 &&
                        <div className="tags">
                            <TagList
                                showTagTitle={props.showTagTitle}
                                onSetTitle={props.onSetTitle}
                                items={props.tarea.tags}
                            />
                        </div>
                    }

                    {props.tarea.users.length > 0 &&
                        <div className="users">
                            <UserList items={props.tarea.users} />
                        </div>
                    }
                </footer>
            </div>
        </li>
    )
});

SortableItem.propTypes = {
    index: PropTypes.number.isRequired,
    tarea: PropTypes.object.isRequired,
}

export default withRouter(SortableItem);
