import React from 'react';
import PropTypes from 'prop-types';

const Filtro = props => {

    const handleChange = e => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : false;
        props.onSetFilterFinalizado(value);
    }

    const handleLimpiarFiltro = e => {
        e.preventDefault(); 
        props.onResetFilter();
    }

    return (
        <form className="filtro-component">
            <div className="row">
                <div className="col filtro-tarea-nombre flex-grow-0">
                    <div className="form-group">
                        <label className="control-label" htmlFor="proyectoFilterNombre">Nombre tarea:</label>
                        <input
                            name="proyectoFilterNombre"
                            type="text"
                            className="form-control"
                            value={props.filterName}
                            onChange={e => props.onSetFilterName(e.target.value)}
                        />
                    </div>
                </div>

                <div className="col filtro-tarea-nombre flex-grow-0">

                    <div className="form-group">
                        <label className="control-label" htmlFor="proyectoFilterFinalizado">¿Desea mostrar los finalizados?</label>
                        <input
                            name="proyectoFilterFinalizado"
                            type="checkbox"
                            className="form-control"
                            checked={props.filterFinalizado}
                            onChange={e => handleChange(e)}
                        />
                    </div>
                </div>

                <div className="col filtro-tarea-reset">
                    <div className="btn-form">
                        <button
                            className="btn btn-secondary"
                            onClick={handleLimpiarFiltro}
                        >Limpiar filtro</button>
                    </div>
                </div>


            </div>
        </form>
    );
}

Filtro.propTypes = {
    filterName: PropTypes.string,
    onSetNameFilter: PropTypes.func,
    filterFinalizado: PropTypes.bool,
    onSetShowFinalizado: PropTypes.func
}

export default Filtro;
