import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

import { API_URL } from '../../constants';

const el = document.createElement('div');

const Contador = (props) => {

    const { proyecto, tarea, timing, open } = props;
    const cont = document.getElementsByClassName('contador_widget')[0];
    
    const purl = API_URL + 'proyecto/update?id=' + proyecto.id;
    const turl = API_URL + 'tarea/update?id=' + tarea.id;

    useEffect(() => {
        return () => {
            const div = document.getElementById('informacion');
            if (div !== null)
                div.remove();

            el.remove();

        };
    }, []);

    const handleRender = () => {
        const div = document.getElementById('informacion');

        if (cont !== null && cont !== undefined && div === null) {

            el.className = 'alert-info alert fade in';
            el.id = 'informacion';
            cont.appendChild(el);

            return (
                ReactDOM.createPortal(
                    <div className='contador_res'>Estás trabajando en el proyecto <a href={purl}>{proyecto.nombre}</a>, tarea <a href={turl}>{tarea.nombre}</a>. Tiempo total trabajado: {timing}</div>,
                    el
                )
            )

        } else {
            return (
                ReactDOM.createPortal(
                    <div className='contador_res'>Estás trabajando en el proyecto <a href={purl}>{proyecto.nombre}</a>, tarea <a href={turl}>{tarea.nombre}</a>. Tiempo total trabajado: {timing}</div>,
                    div
                )
            )

        }
    }

    return (
        <>
            {open &&
                (
                    <>
                        {
                            handleRender()
                        }
                    </>
                )}
        </>
    );
};

export default Contador;