import React from 'react'
import PropTypes from 'prop-types';
import Item from "./item";

const ListSection = (props) => {

    const handleCreate = (tipo, id) => {
        props.onSetTipo(tipo);
        props.onSetId(id);
    }

    return (
        <section style={{ position: 'relative' }}>

            {props.proyectos.map((proyecto, i) => {
                return (
                    <div key={proyecto.id} id={props.idCli + '-' + i}>
                        <header onClick={() => handleCreate('proyecto', proyecto.id)}>
                            <h2><span>{props.nombreCli}</span> - <span>{proyecto.nombre}</span></h2>
                        </header>

                        {proyecto.tareas.map((tarea, i) =>
                            <Item
                                item={tarea}
                                key={tarea.id}
                                scrollEnabled={props.scrollEnabled}
                                onAction={props.onAction}
                                selected={props.currentItem === tarea.id}
                                nombreCli={props.nombreCli}
                                nombrePro={proyecto.nombre}
                                onSetNombreCliente={props.onSetNombreCliente}
                                onSetNombreProyecto={props.onSetNombreProyecto}
                                onSetCurrentItem={props.onSetCurrentItem}
                                onMouseEnter={props.onMouseEnter}
                            />
                        )}
                    </div>
                )
            })}
        </section>
    );
}


ListSection.propTypes = {
    idCli: PropTypes.string.isRequired,
    nombreCli: PropTypes.string.isRequired,
    proyectos: PropTypes.array.isRequired,
    scrollEnabled: PropTypes.bool.isRequired,
    currentItem: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool
    ]).isRequired,
    onSetCurrentItem: PropTypes.func.isRequired,
    onAction: PropTypes.func.isRequired,
    onSetId: PropTypes.func.isRequired,
    onSetTipo: PropTypes.func.isRequired,
    onMouseEnter: PropTypes.func.isRequired
}

export default ListSection;
