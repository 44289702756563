import React, { useState, useEffect } from 'react';

import './style.scss';

const EditableInput = (({ onChange, value }) => {
    const [inputValue, setInputValue] = useState(value);
    const [editing, setEditing] = useState(false);

    useEffect(() => {
        if (inputValue !== value)
            setInputValue(value)
    }, [value]);

    const handleChange = value => {
        setInputValue(value);
    }

    const handleBlur = () => {
        setEditing(!editing);
        onChange(inputValue);
    }

    return (
        <textarea
            value={inputValue}
            rows={1}
            onBlur={handleBlur}
            data-min-row={3}
            className={editing ? 'edit-input is-editing' : 'edit-input'}
            onChange={e => handleChange(e.target.value)}
        />
    )
});

export default EditableInput;