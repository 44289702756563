import React, { useState, useRef, useContext } from 'react';
import { API_URL } from '../../constants/index';
import axios from "axios";
import PropTypes from 'prop-types';
import { ComentariosContext } from "../../context/ComentariosContext";

axios.defaults.withCredentials = true;

const FormCreate = ({ tipo, codigo }) => {
    const [comentario, setComentario] = useState('');
    const textareaRef = useRef(null);
    const disabled = comentario.trim().length === 0;

    const { items, setItems, isLoaded, setLoaded } = useContext(ComentariosContext);

    const createComment = () => {
        const url = API_URL + 'observacionapi';

        const data = {
            texto: comentario.trim(),
            tipo,
            codigo,
            url
        };

        if (data.texto.trim().length > 0) {
            setLoaded(false);
            axios({
                method: 'post',
                data,
                url
            }).then(d => {
                const item = d.data;
                const n = items;
                n.push(item);
                setItems(n);
                setComentario('');

            }).catch(e => {
                window.toastr.error('Error al enviar el comentario', 'Error', {
                    closeButton: true,
                    progressBar: true,
                    timeOut: 5000,
                    extendedTimeOut: 1500
                });
            }).finally(() => {
                setLoaded(true);
            })
        }
    }

    return (
        <div className="comment_form">
            <div className="row">
                <div className="col-md-12">
                    <textarea
                        ref={textareaRef}
                        id="comentario"
                        name="comentario"
                        className="form-control"
                        placeholder="Introduzca comentario"
                        value={comentario}
                        onKeyDown={e => {
                            //cmd + enter
                            if (e.metaKey && e.keyCode === 13)
                                createComment()

                        }}
                        onChange={e => setComentario(e.target.value)}
                    />
                </div>
            </div>

            <div className="observaciones_widget_buttons row">
                <div className="col-md-12 col-md-auto buttons">
                    <button
                        type="submit"
                        disabled={disabled || !isLoaded}
                        className="btn btn-primary"
                        onClick={createComment}
                    >
                        Enviar
                    </button>
                    <button
                        disabled={disabled || !isLoaded}
                        className="btn btn-danger"
                        onClick={() => setComentario('')}
                    >
                        Borrar comentario
                    </button>
                </div>
            </div>
        </div>
    );
}

FormCreate.propTypes = {
    tipo: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,
    codigo: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,
}

export default FormCreate;
