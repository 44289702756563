import React from 'react';
import PropTypes from 'prop-types';

const ProyectoTareaForm = ({ onBack }) => {

    return (
        <form className="proyecto-tarea-form">
            <div className="form-group nombre-proyecto-field">
                <div className="row">
                    <div className="col-md-12">
                        <label className="form-label">Nombre proyecto</label>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <input
                            className="form-control"
                            type="text"
                        ></input>
                    </div>
                </div>
            </div>
            <div className="form-group nombre-tarea-field">
                <div className="row">
                    <div className="col-md-12">
                        <label className="form-label">Nombre Tarea</label>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <input
                            className="form-control"
                            type="text"
                        ></input>
                    </div>
                </div>
            </div>
            <div className="buttons">
                <div className="row">
                    <div className="col-md-12">
                        <button
                            className="btn btn-success"
                        >
                            Crear proyecto y tarea e iniciar
                        </button>
                        <button
                            className="btn btn-danger"
                            onClick={() => onBack()}
                        >
                            Volver a la búsqueda
                        </button>
                    </div>
                </div>
            </div>
        </form>
    );
};

ProyectoTareaForm.propTypes = {
    id: PropTypes.number.isRequired,
    onBack: PropTypes.func.isRequired,
}

export default ProyectoTareaForm;