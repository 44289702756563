import React from 'react';
import PropTypes from 'prop-types';
import User from './User';

const List = props => {

    return (
        props.items.map((user, i) =>
            <User
                key={i}
                user={user}
            />
        )
    );
}

List.propTypes = {
    items: PropTypes.array.isRequired,
}

export default List;
