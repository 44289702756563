import axios from 'axios';
import { API_URL } from '../constants';

const create = async (data) => {
    const url = API_URL + 'api/tareas';
    return await axios({
        method: 'post',
        data,
        url
    }).then(response => {
        return {
            status: true,
            response: response
        }
    }).catch(error => {
        return {
            status: false,
            response: error
        }
    })
}

const update = async (tarea) => {
    const url = API_URL + 'api/tareas/' + tarea.id;
    return await axios({
        method: 'put',
        data: { ...tarea },
        url
    }).then(response => {
        return {
            status: true,
            response: response
        }
    }).catch(error => {
        return {
            status: false,
            response: error
        };
    })
}

export default {
    update,
    create
}