import React, { useState, createRef, useEffect } from 'react';
import { API_URL } from '../../constants';

import axios from 'axios';

const Create = props => {

    const inputRef = createRef();

    const [disabled, setDisabled] = useState(false);
    const [nombre, setNombre] = useState('');

    useEffect(() => {
        if (inputRef !== null && inputRef.current !== null)
            inputRef.current.focus();
    }, [inputRef])

    const handleCrear = e => {

        setDisabled(true);
        e.preventDefault();
        if (nombre.trim().length === 0) {
            props.onClose();
            return;
        }

        const url = API_URL + 'api/listas';

        const data = {
            nombre: nombre,
            proyecto_id: props.pid
        };

        axios({
            method: 'post',
            data,
            url
        }).then(response => {
            if (response.data !== undefined || response.data !== null) {
                console.log('lista creada');
                props.onClose();
                props.onAddItem(response.data);
            }
        }).catch(e => {
            window.toastr.error('Error al crear la lista', 'Error', {
                closeButton: true,
                progressBar: true,
                timeOut: 5000,
                extendedTimeOut: 1500
            });
        })

    }

    const handleClose = () => {
        if (nombre.length === 0)
            props.onClose();

    }

    return (
        <form
            className="crear-lista-form"
            onSubmit={e => handleCrear(e)}
        >
            <div className="row">
                <div className="col-md">
                    <div className="input-lista-nombre form-group">
                        <input
                            className="form-control"
                            onChange={e => setNombre(e.target.value)}
                            onBlur={handleClose}
                            value={nombre}
                            type={'text'}
                            ref={inputRef}
                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="btn-form">
                        <button
                            disabled={disabled}
                            className="btn btn-primary">
                            Crear lista
                            </button>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default Create;