import React, { useState, useRef, useEffect, useContext } from 'react';
import { API_URL } from '../../constants/index';
import axios from "axios";
import { ComentariosContext } from "../../context/ComentariosContext";

axios.defaults.withCredentials = true;

const FormUpdate = props => {
    const [edited, setEdited] = useState(false)
    const [comentario, setComentario] = useState(props.comentario.texto);
    const textareaRef = useRef(null);
    const disabled = comentario.trim().length === 0;

    const { items, setItems } = useContext(ComentariosContext);

    useEffect(() => {
        textareaRef.current.focus();

        const handleEsc = (event) => {

            if (event.keyCode === 27) {
                props.setEditing(false);
            }
        };
        window.addEventListener('keydown', handleEsc);

        return () => {
            window.removeEventListener('keydown', handleEsc);
        };

    });

    const updateComment = () => {

        if (comentario.length === 0) return;

        let url = API_URL + 'observacionapi/' + props.comentario.id;

        const data = {
            texto: comentario,
            usuario: props.comentario.usuario + ' (editado)',
            edited: '1',
            tipo: props.comentario.tipo,
            codigo: props.codigo,
            url
        }

        if (data.texto.trim().length > 0 && edited) {
            axios({
                method: 'put',
                data,
                url
            }).then(d => {
                const item = d.data;
                const n = items.map(e => {
                    if (e.id === item.id)
                        return item;
                    else
                        return e;
                });

                setItems(n);
            }).catch(e => {
                window.toastr.error('Error al editar el comentario', 'Error', {
                    closeButton: true,
                    progressBar: true,
                    timeOut: 5000,
                    extendedTimeOut: 1500
                });
            })
        }

        props.setEditing(false);
    }

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <textarea
                        ref={textareaRef}
                        id="comentario"
                        name="comentario"
                        className="form-control"
                        placeholder="Introduzca comentario"
                        value={comentario}
                        onBlur={updateComment}
                        tabIndex="0"
                        onKeyPress={e => {

                            if (e.metaKey && e.keyCode === 13)
                                updateComment()
                        }}
                        disabled={props.error ? disabled : false}
                        onChange={e => { setComentario(e.target.value); setEdited(true) }}
                    />
                </div>
            </div>
        </>
    );
}

export default FormUpdate;
