import React, { useState, useEffect } from 'react';
import { API_URL } from '../../constants';
import Spinner from '../Spinner';

import SortableContainer from './SortableContainer';
import SortableList from './SortableList';

import arrayMove from 'array-move';
import axios from 'axios';

const ListasProyecto = props => {

    const pid = props.pid;
    const [items, setItems] = useState([]);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (pid !== undefined) {

            const url = API_URL + 'api/proyectos/' + pid;
            axios.get(url)
                .then(response => {
                    setItems(response.data.milestones);
                    setLoaded(true);
                })
                .catch(error => {
                    console.log(error);
                })
        }
    }, []);

    const handleSortEnd = ({ oldIndex, newIndex }) => {
        if (oldIndex === newIndex) return;

        let newItems = arrayMove(items, oldIndex, newIndex);
        let orden = [];
        newItems.map((item) => orden.push(item.id));

        if (newItems !== items) {

            const url = API_URL + 'api/milestones/ordenar';
            const params = new URLSearchParams();
            params.append('orden', orden);

            axios({
                method: 'post',
                url,
                data: params
            }).then(d => {}).catch(e => {})
            setItems(newItems);
        }
    };

    return (
        <>
            {loaded ? (
                <SortableContainer lockAxis="y" onSortEnd={(a) => handleSortEnd(a)} useDragHandle>
                    {items.map((item, i) => {

                        return (
                            <SortableList
                                key={i}
                                i={i}
                                milestone={item}
                            />
                        )
                    })}
                </SortableContainer>
            ) : (
                    <Spinner />
                )
            }

        </>
    )
}

export default ListasProyecto;
