import React from 'react';

import PropTypes from 'prop-types';

const User = ({ user }) => {

    return (
        <span
            className='user'
            style={{
                // marginLeft: 20,
            }}
            data-toggle="tooltip"
            data-placement="top"
            title={user.nombre}
        >
            <img
                src={user.imagen_perfil}
                alt=""
                className="img-avatar"
            />
        </span>
    );
}

User.propTypes = {
    user: PropTypes.object.isRequired,
}

export default User;
