
const getNextPreviewIDTarea = (datos, id, next = true) => {
    let res = false;
    let breakLoop = false;
    let previous = false;
    let encontrado = undefined;

    for (const cliente of datos) {

        for (const proyecto of cliente.proyectos) {

            for (const tarea of proyecto.tareas) {

                if (tarea.id === id) {
                    if (!next) {
                        res = previous;
                        breakLoop = true;
                        break;

                    }
                    encontrado = true;

                }
                else {
                    if (encontrado && res === false) {
                        res = tarea.id;
                        break;
                    }
                }

                previous = tarea.id;
            }
            if (breakLoop) break;
        }

        if (breakLoop) break;
    }

    return res;
}

const getFirstTareaID = (datos) => {

    let res = false;

    for (const cliente of datos) {

        for (const proyecto of cliente.proyectos) {

            if (typeof proyecto.tareas !== 'undefined' && proyecto.tareas.length > 0) {
                res = proyecto.tareas[0].id;
                break;
            }
        }

        if (res !== false) break;
    }

    return res;
}

const flattenTareas = (datos) => {

    let res = [];

    for (const cliente of datos) {

        for (const proyecto of cliente.proyectos) {
            res = [...res, ...proyecto.tareas];
        }
    }
    return res;

}

const f_getTotalItems = (datos) => {
    return flattenTareas(datos).length;
}

const f_getFirstTareaID = (datos) => {

    const tareas = flattenTareas(datos);
    return tareas.length > 0 ? tareas[0].id : false;

}

const f_getNextPreviewIDTarea = (datos, id, next = true) => {
    const tareas = flattenTareas(datos);

    const index = tareas.findIndex(t => t.id === id);

    if (index < 0)
        return false;

    if (index === 0 && !next)
        return false;

    if (index > 0 && next && (index === tareas.length || (index + 1) === tareas.length))
        return false;

    if (next)
        return tareas[index + 1].id;
    else
        return tareas[index - 1].id;
}

const f_getTareaItem = (datos, currentItem) => {
    const tareas = flattenTareas(datos);
    return tareas.filter((item, i) => item.id === currentItem)[0];
}



export default {
    getFirstTareaID,
    getNextPreviewIDTarea,
    f_getFirstTareaID,
    f_getNextPreviewIDTarea,
    f_getTotalItems,
    f_getTareaItem
}
