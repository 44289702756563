import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tarea from '../../util/tarea';

const TareaForm = (props) => {

    const [nombre, setNombre] = useState();

    const handleCreate = (e) => {
        e.preventDefault();

        Tarea.create({ proyecto_id: props.id, nombre }).then((value) => {
            if (value.status) {
                props.onAction({ id: value.response.data.id, tipo: 'tarea' });
            } else {
                window.toastr.error('Error al crear la tarea', 'Error', {
                    closeButton: true,
                    progressBar: true,
                    timeOut: 5000,
                    extendedTimeOut: 1500
                });
            }
        })
    }

    return (
        <form className="proyecto-tarea-form" onSubmit={e => handleCreate(e)}>
            <div className="form-group nombre-proyecto-field">
                <div className="row">
                    <div className="col-md-12">
                        <label>Nombre tarea</label>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <input
                            className="form-control"
                            onChange={e => setNombre(e.target.value)}
                            type="text"
                        ></input>
                    </div>
                </div>
            </div>
            <div className="buttons">
                <div className="row">
                    <div className="col-md-12">
                        <button
                            className="btn btn-success"
                            disabled={nombre === undefined || nombre.trim().length === 0}
                        >
                            Crear tarea e iniciar
                        </button>
                        <button
                            className="btn btn-danger"
                            onClick={() => props.onBack()}
                        >
                            Volver a la búsqueda
                        </button>
                    </div>
                </div>
            </div>
        </form>
    );
};

TareaForm.propTypes = {
    onBack: PropTypes.func.isRequired,
}

export default TareaForm;