import React from 'react';
import ReactDOM from 'react-dom';
import Comentarios from "./components/Comentarios";
import Temporizador from "./components/Temporizador";
import Proyecto from "./components/Proyecto/index";
import Listas from './components/Listas';
import Milestones from './components/Milestones';

import * as serviceWorker from './serviceWorker';

const renderRComponent = (component, className) => {

    const wrappers = document.getElementsByClassName(className);
    if (wrappers && wrappers.length > 0)
        Array.from(wrappers)
            .forEach((el, i) => {
                const c = React.createElement(component, {...el.dataset});
                ReactDOM.render(c, el);
            });
}

renderRComponent(Milestones, 'milestones_widget');
renderRComponent(Listas, 'listas_widget');
renderRComponent(Proyecto, 'proyecto_widget');
renderRComponent(Comentarios, 'observaciones_widget');
renderRComponent(Temporizador, 'temporizador_widget');


serviceWorker.unregister();
