import React, { useEffect, useState } from 'react'
import { API_URL } from '../../constants';
import Error from '../Error';
import axios from "axios";
import Spinner from '../Spinner';
import List from "./List";
import Form from "./FormCreate";
import { ComentariosContext } from "../../context/ComentariosContext";

axios.defaults.withCredentials = true;

const Index = props => {

    const { tipo, codigo } = props;
    const url = API_URL + 'observacionapi?filter[tipo]=' + tipo + '&filter[codigo]=' + codigo;
    const [editingItemId, setEditingItemId] = useState();
    const [items, setItems] = useState([]);
    const [isError, setError] = useState(false);
    const [isLoaded, setLoaded] = useState(false);
    const [utoken, setUToken] = useState(window.token);

    useEffect(() => {
        setError(false);
        setLoaded(false);
        axios.get(url)
            .then(response => {
                setItems(response.data);
            })
            .catch(error => {
                setError(true);
            })
            .finally(() => {
                setLoaded(true);
            });
    }, [codigo]);

    return (
        <section className={props.className || ''}>
            <ComentariosContext.Provider
                value={{
                    editingItemId,
                    setEditingItemId,

                    items,
                    setItems,

                    utoken,
                    setUToken,

                    isLoaded,
                    setLoaded
                }}>
                {
                    isLoaded ? (
                        !isError ?
                            (
                                <List />
                            ) :
                            (
                                <Error />
                            )

                    ) : (
                            <Spinner />
                        )
                }
                <Form
                    tipo={tipo}
                    codigo={codigo}
                />

            </ComentariosContext.Provider>
        </section>
    )
}

export default Index;
