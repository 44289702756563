import React from 'react';
import Modal from '../Modal';
import PropTypes from 'prop-types';

import DetenerForm from './detenerForm';

const Detener = props => {

    const { onHide, show, onStop } = props;

    const handleClose = () => {
        onHide();
    }

    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <div className="card-header">
                <strong>Detener temporizador</strong>
            </div>

            <div className="card-body">
                <div className="row">
                    <div className="col">
                        <DetenerForm
                            tnombre={props.tnombre}
                            onStop={() => onStop()}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );
};

Detener.propTypes = {
    show: PropTypes.bool.isRequired,
    onStop: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired
}
export default Detener;
