import React from 'react';

import PropTypes from 'prop-types';
import './style.scss';

const Tag = props => {

    const showTitle = props.showTagTitle;
    const {tag} = props

    return (
        <span
            onClick={() => {
                props.onSetTitle(!showTitle)
                window.$("[data-toggle='tooltip']").tooltip('hide');
            }}
            data-toggle={showTitle ? 'tooltip-hide' : 'tooltip'}
            data-placement="top"
            className={showTitle ? 'tag showing-title' : 'tag hiding-title'}
            title={tag.title}
            style={{
                backgroundColor: tag.background_color,
                color: tag.text_color,
                marginRight: 10
            }}
        >
            <span>{tag.title}</span>
        </span>
    );
}

Tag.propTypes = {
    tag: PropTypes.object.isRequired,
}

export default Tag;
