import React, { useState, useEffect } from 'react';
import { API_URL } from '../../constants';
import Spinner from '../Spinner';
import ListaCreate from './ListaCreate';

import SortableContainer from './SortableContainer';
import SortableList from './SortableList';

import arrayMove from 'array-move';
import axios from 'axios';

const ListasProyecto = props => {

    const pid = props.pid;
    const [items, setItems] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [creating, setCreating] = useState(false);

    useEffect(() => {
        if (pid !== undefined) {

            const url = API_URL + 'api/proyectos/' + pid;
            axios.get(url)
                .then(response => {
                    setItems(response.data.listas);
                    setLoaded(true);
                })
                .catch(error => {
                    console.log(error);
                })
        }
    }, []);

    const handleAddItem = newItem => {
        const ar = [];

        items.map((item, i) => {
            return ar.push(item);
        })

        ar.push(newItem);

        setItems(ar);
    }

    const handleSortEnd = ({ oldIndex, newIndex }) => {
        if (oldIndex === newIndex) return;

        let newItems = arrayMove(items, oldIndex, newIndex);
        let orden = [];
        newItems.map((item) => orden.push(item.id));

        if (newItems !== items) {

            const url = API_URL + 'api/listas/ordenar';
            const params = new URLSearchParams();
            params.append('orden', orden);

            axios({
                method: 'post',
                url,
                data: params
            }).then(d => {
                console.log(newItems);
                console.log('actualizado');
            }).catch(e => {
                console.log(e);
            })
            console.log('actualizado');
            setItems(newItems);
        }
    };

    return (
        <>
            {loaded ? (
                <>
                    <SortableContainer lockAxis="y" onSortEnd={(a) => handleSortEnd(a)} useDragHandle>
                        {items.map((item, i) => {
                            return (
                                <SortableList
                                    key={i}
                                    i={i}
                                    list={item}
                                />
                            )
                        })}
                    </SortableContainer>
                    {creating ? (
                        <ListaCreate
                            pid={pid}
                            onClose={() => setCreating(false)}
                            onAddItem={v => handleAddItem(v)}
                        />
                    ) : (
                            <button onClick={() => setCreating(true)} className="btn btn-primary btn-create">
                                Crear lista
                            </button>
                        )}
                </>
            ) : (
                    <Spinner />
                )
            }

        </>
    )
}

export default ListasProyecto;
