import React, { useContext, useState } from 'react';
import TareaCreate from './TareaCreate';
import { TareaDetalleContext } from "../../context/TareaDetalleContext";

const BotonesAccion = (props) => {

    const { creating, tarea, setCreating, updateProperty } = useContext(TareaDetalleContext);

    const [disabled, setDisabled] = useState(false);

    return (
        <div className="buttons">
            <div className="row">
                <div className="col-12">
                    {creating ? (
                        <TareaCreate
                            proyecto_id={tarea.proyecto_id}
                            tarea_id={tarea.id}
                            lista_id={tarea.lista_id}
                            onClose={() => setCreating(false)}
                            onCreate={v => updateProperty(v, 'hijas')}
                        />
                    ) : (
                            <div >
                                <button className="btn btn-link" onClick={() => window.open(tarea.updateUrl)}>Editar tarea <span className="fa fa-external-link"></span></button>
                                {
                                    tarea.finalizar === 0 && (
                                        <button className="btn btn-primary" onClick={() => setCreating(true)}>
                                            Crear tarea hija
                                        </button>
                                    )
                                }
                                {!window.working && tarea.finalizar === 0 &&
                                    (
                                        <button
                                            className="btn btn-warning"
                                            onClick={() => window.location.assign('/tarea/iniciar?id=' + tarea.id)}
                                        >
                                            Iniciar
                                        </button>
                                    )
                                }
                                {tarea.finalizar === 0 && <button className="btn btn-success" disabled={disabled} onClick={() => { setDisabled(true); updateProperty(1, 'finalizar'); setDisabled(false); }} > Finalizar</button>}

                            </div>
                        )}
                </div>
            </div>
        </div>
    )
}

export default BotonesAccion;