import React from 'react';
import PropTypes from 'prop-types';
import Tag from './Tag';

const List = props => {
    return (
        props.items.map((tag, i) =>
            <Tag
                key={i}
                showTagTitle={props.showTagTitle}
                onSetTitle={props.onSetTitle}
                tag={tag}
            />
        )
    );
}

List.propTypes = {
    items: PropTypes.array.isRequired,
}

export default List;
