import React, { useState, createRef, useEffect } from 'react';
import Tarea from '../../util/tarea';

const Create = props => {

    const [nombre, setNombre] = useState('');
    const [disabled, setDisabled] = useState(true);
    const inputRef = createRef();

    const tarea = {
        proyecto_id: props.proyecto_id,
        tarea_id: props.tarea_id !== undefined ? props.tarea_id : null,
        lista_id: props.lista_id !== undefined ? props.lista_id : null
    }

    useEffect(() => {
        if (inputRef !== null && inputRef.current !== null)
            inputRef.current.focus();
    }, [inputRef])

    const handleChange = value => {
        if (value.trim().length > 0)
            setDisabled(false);
        else
            setDisabled(true);

        setNombre(value);
    }

    const handleCrear = e => {

        setDisabled(true);
        e.preventDefault();

        if (nombre.trim().length === 0) {
            props.onClose();
            return;
        }

        Tarea.create({ ...tarea, nombre }).then((value) => {
            if (value.status) {
                if ((value.response.data !== undefined || value.response.data !== null) && props.onCreate !== undefined) {
                    props.onClose();
                    props.onCreate(value.response.data);
                }
            } else {
                window.toastr.error('Error al crear la tarea', 'Error', {
                    closeButton: true,
                    progressBar: true,
                    timeOut: 5000,
                    extendedTimeOut: 1500
                });
            }
        })

    }

    const handleClose = () => {
        if (nombre.length === 0)
            props.onClose();

    }

    return (
        <div>
            <form
                onSubmit={e => handleCrear(e)}
            >
                <div className="row">
                    <div className="col">
                        <div className="input-group">
                            <input
                                className="form-control"
                                onBlur={handleClose}
                                onChange={e => handleChange(e.target.value)}
                                value={nombre}
                                type={'text'}
                                ref={inputRef}
                            />

                            <div className="input-group-append">
                                <button
                                    disabled={disabled}
                                    className="btn btn-primary"
                                >
                                    Crear tarea
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default Create;
