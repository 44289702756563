import React from 'react';
import PropTypes from 'prop-types';

import { sortableElement, sortableHandle } from 'react-sortable-hoc';

const DragHandle = sortableHandle(() => <i className="icon-menu sortable" />);

const SortableItem = sortableElement(props =>
    <li tabIndex="0">
        <div data-id={props.milestone.id} className={'list-item draggableItem '}>
            <div className="list-item-body">
                <DragHandle />
                <a
                    href={props.milestone.updateUrl}
                >
                    {props.milestone.nombre}
                </a>
            </div>
        </div>
    </li>)

SortableItem.propTypes = {
    index: PropTypes.number.isRequired,
    milestone: PropTypes.object.isRequired,
}

export default SortableItem;
