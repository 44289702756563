import React, { useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

import { API_URL } from '../../constants';

const DetenerForm = (props) => {

    const [descripcion, setDescripcion] = useState('');

    const handleDetener = (props, finalizar = false) => {

        const params = new URLSearchParams();
        let url = API_URL + 'api/tiempos/detener-tarea';
        if (finalizar)
            params.append('finalizar', finalizar);
        params.append('descripcion', descripcion);
        axios({
            method: 'post',
            url: url,
            data: params
        }).then(response => {
            if (response.data.status) {
                let msg = 'La tarea <a href="' + API_URL + 'tiempo/update?id=' + response.data.tiempo_id + '" target="_blank">' + props.tnombre + '</a> ha sido detenida.';
                if (finalizar)
                    msg = 'La tarea <a href="' + API_URL + 'tiempo/update?id=' + response.data.tiempo_id + '" target="_blank">' + props.tnombre + '</a> ha sido detenida y finalizada.';

                window.toastr.success(msg, 'Correcto', {
                    closeButton: true,
                    progressBar: true,
                    timeOut: 5000,
                    extendedTimeOut: 1500
                })
                props.onStop();
            } else {
                window.toastr.error(response.data.data, 'Error', {
                    closeButton: true,
                    progressBar: true,
                    timeOut: 5000,
                    extendedTimeOut: 1500
                });
            }

        });
    }

    return (
        <form className="detener_form">
            <div className="temporizador-widget">
                <div className="form-group descripcion-field">
                    <div className="row">
                        <div className="col-md-12">
                            <label className="form-label">Descripción</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <textarea
                                rows="6"
                                className="form-control"
                                onChange={e => setDescripcion(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="buttons">
                    <div className="row">
                        <div className="col-md-12">
                            <button
                                onClick={e => { e.preventDefault(); handleDetener(props); }}
                                className="btn btn-success"
                            >
                                Detener
                        </button>
                            <button
                                onClick={e => { e.preventDefault(); handleDetener(props, true); }}
                                className="btn btn-primary"
                            >
                                Detener y finalizar tarea
                        </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
};

DetenerForm.propTypes = {
    onStop: PropTypes.func.isRequired
}

export default DetenerForm;