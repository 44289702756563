const pad = item => {
    if (item < 10)
        return '0' + item;
    return item;
}

const toUTC = (fecha) => {
    var newDate = new Date(Date.parse(fecha)).toLocaleString('es-ES', {
        hour12: false,
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        minute: '2-digit',
        hour: '2-digit', 
        second: '2-digit'
    }).split(" ");

    if(newDate[0] === 'Invalid')
        return fecha;
    
    var time = newDate[1];
    var mdy = newDate[0];

    mdy = mdy.split('/');
    var month = mdy[1];
    var day = mdy[0];
    var year = mdy[2];

    return day + '-' + month + '-' + year + ' ' + time;
}

const getNowToString = () => {
    let date = new Date();

    let year = date.getFullYear();
    let month = (date.getMonth() + 1);
    let day = date.getDate();
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();

    return pad(year) + '-' + pad(month) + '-' + pad(day) + ' ' + pad(hours) + ':' + pad(minutes) + ':' + pad(seconds);
}


export default {
    getNowToString,
    toUTC
}